﻿%h1-heading {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 15px;
    margin-right: 15px;
    padding-bottom: 5px;
    border-bottom: 3px solid #03a9f4;
    @include breakpoint($bp-tablet) {
        font-size: 20px;
        font-size: 2.0rem;
        margin: 0 0 20px 0;
    }
    i.fa {
        margin-right: 5px;
        color: $main-color;
        font-size: 20px;
        font-size: 2.0rem;
    }
}

%h2-heading {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: bold;
    @include breakpoint($bp-tablet) {
        font-size: 16px;
        font-size: 1.6rem;
        color: #0073a7;
    }
    i.fa {
        margin-right: 5px;
    }
}
