﻿.seatSelect__title {
    @extend %h1-heading;
}

.timeTable__header {
    width: 100%;
    border-collapse: collapse;
}

.timeTable__body {
    width: 100%;
    border-collapse: collapse;
}

.timeTable__headerCell {
    $this: &;
    width: 12%;
    border: 1px solid #e0e0e0;
    text-align: center;
    font-size: 11px;
    font-size: 1.1rem;
    padding: 5px 0;
    &--header {
        border: 1px solid #e0e0e0;
        font-size: 11px;
        font-size: 1.1rem;
        width: 16%;
        padding: 0;
    }
    &--sat {
        @extend #{$this};
        background: #dcf4ff;
        color: #006b9c;
    }
    &--sun {
        @extend #{$this};
        background: #ffdcdc;
        color: #dc0000;
    }
}

.timeTable__bodyCell {
    width: 12%;
    border: 1px solid #e0e0e0;
    text-align: center;
    font-size: 16px;
    font-size: 1.6rem;
    padding: 6px 10px;
    &--header {
        border: 1px solid #e0e0e0;
        font-size: 11px;
        font-size: 1.1rem;
        width: 16%;
        padding: 0;
        font-weight: normal;
    }
    &.is-full {
        background: #efefef;
        color: #aaa;
    }
    &.is-na {
        background: #efefef;
        color: #aaa;
    }
    a {
        color: #03a9f4;
        display: block;
    }
    span {
        text-align: center;
    }
}

.storeInfo {
    padding: 10px;
    background: #f3f3f3;
    margin-bottom: 10px;
    position: relative;
    ;
}

.storeInfo__name {
    margin-bottom: 6px;
    >strong {
        font-size: 16px;
        font-size: 1.6rem;
        margin-right: 10px;
    }
}

// キッズ受入可アイコン
// .seatSlect__kidsStatus {
//     background: #ff5722;
//     color: $white;
//     border-radius: 3px;
//     font-size: 12px;
//     font-size: 1.2rem;
//     padding: 3px 8px;
//     position: absolute;
//     top: 10px;
//     right: 10px;
// }
.weekChange {
    margin-bottom: 10px;
    @include clearfix();
}

.weekChange__item {
    &--prev {
        float: left;
        .button {
            padding: 0 10px;
        }
    }
    &--next {
        float: right;
        .button {
            padding: 0 10px;
        }
    }
}

.dayChange {
    margin-bottom: 10px;
    @include clearfix();
    @include breakpoint($bp-tablet) {
        float: left;
        margin-right: 15px;
    }
}

.dayChange__item {
    &--date {
        display: inline-block;
        width: 100%;
        padding: 0 0 0 5px;
        vertical-align: middle;
        @include breakpoint($bp-tablet) {}
        i.fa {
            font-size: 20px;
            font-size: 2.0rem;
            margin-right: 8px;
            color: #00aff7;
        }
        input {
            width: 40%;
            @include breakpoint($bp-tablet) {
                width: 120px;
            }
        }
    }
}

.infoLink {
    font-size: 0;
}

.infoLink__item {
    display: inline-block;
    margin-right: 18px;
    >a {
        font-size: 14px;
        font-size: 1.4rem;
        >i.fa {
            margin-right: 8px;
        }
    }
    &:last-child {
        margin-right:0;
    }
}

.resourceTable {
    min-height: 350px;
    overflow: auto; // -webkit-overflow-scrolling: touch;
    @include breakpoint($bp-tablet) {
        width: 100%;
    }
}

.resourceTable__table {
    border-collapse: collapse;
    height: 1%;
    @include breakpoint($bp-tablet) {
        width: 100%;
    }
    thead {
        th {
            border: 1px solid #abd6e8;
            text-align: center;
            padding: 3px 0;
            background: #d7f3ff;
        }
        td {
            padding: 3px 0;
            border: 1px solid #abd6e8;
            text-align: center;
            background: #d7f3ff;
        }
    }
    tbody {
        tr {
            border-bottom: 1px solid #ccc;
        }
        tr:first-child {
            .resourceTable__nameCell {}
        }
        tr:last-child {
            .resourceTable__nameCell {}
        }
        .resourceTable__nameCell {
            border-right: none;
            font-weight: normal;
            font-size: 10px;
            font-size: 1.0rem;
            padding: 10px 0 10px 5px;
            text-align: left;
            display: inline-block;
            width: 35vw; // white-space: nowrap;
            @include breakpoint($bp-tablet) {
                font-size: 14px;
                font-size: 1.4rem;
                padding: 6px 0 6px 5px;
                display: inline-block;
                width: 200px;
            }
        }
        .resourceTable__cell {
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            text-align: center;
            // background: $is-reserve;
            background: #ddd;
            padding: 0;
            height: 100%;
            >a {
                display: block;
                width:100px;
                height: 100%;
                text-decoration: none;
                font-size: 0;
                @include breakpoint($bp-tablet) {
                    width: 65px;
                    height: 100%;
                }
                div {
                    display: inline-block;
                    width: 25%;
                    height: 100%;
                    // background: $is-na;
                    background: $white;
                    border-right: 1px dotted #ddd;
                    transition: all ease 0.2s;
                    &:hover {
                        background: #a8e6cf !important;
                    }
                    &.is-na {
                        background: $is-na;
                    }
                    &.is-active {
                        background: $white;
                    }
                    &.is-reserved {
                        background: #ff9c9c;
                        border-right: 1px dotted #e47272;
                    }
                    &.is-others {
                        background: #cbf1d3;
                        border-right: 1px dotted #8fc79b;
                    }
                    &.is-provision {
                        background: #a8e6cf;
                        border-right: 1px dotted #78ceae;
                    }
                    &.is-regular {
                        background: #cbf1d3;
                        border-right: 1px dotted #8fc79b;
                    }
                    &.is-irregular {
                        background: #ff9c9c;
                        border-right: 1px dotted #e47272;
                    }
                    &.is-applying {
                        background: #5e95ed;
                        border-right: 1px dotted #78ceae;
                    }
                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
    }
}

.resourceTable__headerDate,
.resourceTable__nameCell {
    border-right: none !important;
}

.resourceTable__headerIcon {
    border-left: none !important;
    border-right: none !important;
}

.resourceTable__iconCell {
    $this: &; // border: 1px solid #e9e9e9;
    border-left: none;
    border-right: none;
    color: #ddd;
    text-align: center;
    &--monitor {
        @extend #{$this};
        &.is-active {
            color: #4caf50;
        }
    }
    &--key {
        @extend #{$this};
        &.is-active {
            color: #ff9800;
        }
    }
}

.resourceTable.fixed_header_display_none_at_print {
    border-right: 2px solid #e9e9e9;
    box-shadow: 4px 0 3px -3px rgba(0, 0, 0, 0.2);
}


/* rmodal */

body.modal-open {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    display: none;
    background: rgba(0, 0, 0, .30);
    z-index: 999;
    padding: 30px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.modal .modal-dialog {
    position: relative;
    margin: 30px auto;
    width: 1100px;
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    background: #fff;
    margin: 0 auto;
}

.animationForm,
.reserveConfirm,
.loginForm {
    position: fixed !important;
    // transition: all ease 0.15s;
    // transform: translateY(10%);
    opacity: 0;
    &.animated {
        // opacity: 1;
        // transform: translateY(0);
    }
}

// .storeMap,
// .seatsMap{
//     position:fixed;
//     transition: all ease 0.15s;
//     transform: translateY(400px);
//     top: 0 !important;
//     opacity: 0;
//     &.animated {
//         opacity: 1;
//         transform: translateY(10px);
//     }
// }

.reserveDialog {
    // height: 250px;
    -webkit-overflow-scrolling: touch;
}

.reserveDialog__table {
    width: 100%;
    tr {
        th {
            display: block;
            width: 100%;
            padding: 5px;
            background: #f3f3f3;
            font-weight: normal;
            text-align: left;
            @include breakpoint($bp-tablet) {
                display: table-cell;
                width: auto;
            }
        }
        td {
            display: block;
            width: 100%;
            padding: 8px;
            @include breakpoint($bp-tablet) {
                display: table-cell;
                width: auto;
            }
        }
    }
}

.reserveDialog__btns {
    li {
        @extend %button_list;
    }
}

/**/

.searchLink {
    font-size: 0;
    @include breakpoint($bp-tablet) {
        @include clearfix();
    }
    .searchLink__item {
        width: 100%;
        display: inline-block;
        @include breakpoint($bp-tablet) {
            width: 49%;
            float: left;
        }
        &:first-child {
            margin-bottom: 10px;
            @include breakpoint($bp-tablet) {
                margin-bottom: 0px;
                margin-right: 2%;
            }
        }
        .button {
            @include breakpoint($bp-tablet) {
                width: 100%;
                font-size: 14px;
                font-size: 1.4rem;
                padding: 8px 14px;
            }
        }
    }
}

.research {
    margin-top: 15px;
    margin-bottom: 0px;
    padding: 10px;
    background: #eee;
    border-radius: 3px;
    @include breakpoint($bp-tablet) {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

.research__title {
    margin: -20px 0 3px 0;
    text-align: center;
}


/*examples 凡例 */

.examples {
    padding: 0 5px;
    background: #f4f4f4;
    @include breakpoint($bp-tablet) {
        float: left;
        padding: 8px;
        position: relative;
        top: 3px;
    }
}

.examples--icon {
    padding: 0 5px;
    background: #f4f4f4;
    @include breakpoint($bp-tablet) {
        float: left;
        margin-left: 0;
        padding: 8px;
        position: relative;
        top: 3px;
    }
}

.label__item {
    $this: &;
    font-size: 11px;
    font-size: 1.1rem;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
    @include breakpoint($bp-tablet) {
        vertical-align: text-top;
    }
    &:last-child {
        margin-right: 0;
    }
    .mark {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        box-sizing: border-box;
        vertical-align: middle;
        margin-right: 5px;
        position: relative;
        top: -1px;
    }
}

.examples__item {
    $this: &;
    font-size: 11px;
    font-size: 1.1rem;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
    @include breakpoint($bp-tablet) {
        vertical-align: text-top;
    }
    &:last-child {
        margin-right: 0;
    }
    &:before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        box-sizing: border-box;
        vertical-align: middle;
        margin-right: 5px;
        position: relative;
        top: -1px;
    }
    &--empty {
        @extend #{$this};
        &:before {
            border: 1px solid #ccc;
            background: $white;
        }
    }
    &--na {
        @extend #{$this};
        &:before {
            background: $is-na;
        }
    }
    &--reserve {
        @extend #{$this};
        &:before {
            background: $is-reserve;
        }
    }
    &--regular {
        @extend #{$this};
        &:before {
            background: $is-others;
        }
    }
    &--irregular {
        @extend #{$this};
        &:before {
            background: $is-reserve;
        }
    }
    &--applying {
        @extend #{$this};
        &:before {
            background: $is-applying;
        }
    }
}

.exclusiveSpace__notice {
    text-align: center;
    margin-bottom: 10px;
}

#datepicker-use-date {
    border: 2px solid #00aff7;
}

.button.search-dedicated {
    padding: 10px 14px;
}

/**/

.tippy-tooltip{
    padding: 6px 10px;
    text-align: left;
    background-color:rgba(0,0,0,.8);
    font-size: 1.0rem;
    @include breakpoint($bp-tablet) {
        font-size: 1.2rem;
    }
}

/**/

.provision_list{
    padding-top: 10px;
}
