﻿.applications-wrapper{
    @extend %indent;
    @include breakpoint($bp-tablet) {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.reslut-meta__edit{
    float: right;
    margin-bottom: 5px;
    .button{
        width: 4em;
        padding: 2px 8px;
    }
}

#construction-list{
    transition:all ease 0.3s;
    &.editable{
        padding-left: 50px;
    }
}

#applications-tabs{
    .ui-tabs-nav{
        li{
            width: 49%;
            margin-right:0;
            &:nth-child(2){
                float:right;
            }
            a{
                width: 100%;
                padding: 10px 0;
                text-align: center;
                font-weight: bold;
            }
        }
    }
}

.applications-tab-content{
    @include breakpoint($bp-tablet) {
        padding: 15px !important;
    }
}


.searchHistory__title {
    @extend %h1-heading;
}

.searchHistory__conditionsTitle {
    @extend %h2-heading;
    margin: 0;
    text-align: center;
}

.berth-list,
.construction-list {
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box;
    display: block;
    padding: 10px;
    background: #f0f0f0;
    @include breakpoint($bp-tablet) {
        display: table;
        // margin-bottom: 20px;
        padding: 0;
        background: #fff;
    }
    tbody {
        display: block;
        @include breakpoint($bp-tablet) {
            display: table-row-group;
        }
    }
}

.berth-list-thead-tr{
    display: none;
    @include breakpoint($bp-tablet) {
        display: table-row;
    }
    th {
        display: block;
        background: #cef0ff;
        @include breakpoint($bp-tablet) {
            display: table-cell;
            border: 1px solid #b3dcef;
            font-size: 14px;
            font-size: 1.4rem;
        }
        &:nth-child(1){
            @include breakpoint($bp-tablet) {
                width: 13%;
            }
        }
        &:nth-child(2){
            @include breakpoint($bp-tablet) {
                width: 29%;
            }
        }
        &:nth-child(3){
            @include breakpoint($bp-tablet) {
                width: 29%;
            }
        }
        &:nth-child(4){
            @include breakpoint($bp-tablet) {
                width: 29%;
            }
        }
    }
}

.construction-list-thead-tr {
    display: none;
    @include breakpoint($bp-tablet) {
        display: table-row;
    }
    th {
        display: block;
        background: #cef0ff;
        @include breakpoint($bp-tablet) {
            display: table-cell;
            border: 1px solid #b3dcef;
            font-size: 14px;
            font-size: 1.4rem;
        }
        &:nth-child(1){
            @include breakpoint($bp-tablet) {
                width: 2%;
            }
        }
        &:nth-child(2){
            @include breakpoint($bp-tablet) {
                width: 8%;
            }
        }
        &:nth-child(3){
            @include breakpoint($bp-tablet) {
                width: 9%;
            }
        }
        &:nth-child(4){
            @include breakpoint($bp-tablet) {
                width: 7%;
            }
        }
        &:nth-child(5){
            @include breakpoint($bp-tablet) {
                width: 8%;
            }
        }
        &:nth-child(6){
            @include breakpoint($bp-tablet) {
                width: 8%;
            }
        }
        &:nth-child(7){
            @include breakpoint($bp-tablet) {
                width: 14%;
            }
        }
        &:nth-child(8){
            @include breakpoint($bp-tablet) {
                width: 14%;
            }
        }
        &:nth-child(9){
            @include breakpoint($bp-tablet) {
                width: 14%;
            }
        }
        &:nth-child(10){
            @include breakpoint($bp-tablet) {
                width: 14%;
            }
        }
    }
}

.berth-list-tbody-td{
    $this: &;
    display: block;
    font-size: 10px;
    font-size: 1.0rem;
    width: 50%;
    float: left;
    margin: 0;
    padding: 0;
    transition: all ease 0.2s;
    @include breakpoint($bp-tablet) {
        width: auto;
        display: table-cell;
        float: none;
        border: 1px solid #ddd;
        padding: 10px;
        font-size: 14px;
        font-size: 1.4rem;
    }
    &--date,
    &--first,
    &--second,
    &--third{
        @extend #{$this};
        width: 100%; // margin-right: 1%;
        @include breakpoint($bp-tablet) {
            width: 90px;
            text-align: center;
        }
    }
}

.construction-list-tbody-td {
    $this: &;
    display: block;
    font-size: 10px;
    font-size: 1.0rem;
    width: 50%;
    float: left;
    margin: 0;
    padding: 0;
    transition: all ease 0.2s;
    @include breakpoint($bp-tablet) {
        width: auto;
        display: table-cell;
        float: none;
        border: 1px solid #ddd;
        padding: 10px 0;
        font-size: 14px;
        font-size: 1.4rem;
    }
    &--date1,
    &--date2{
        @extend #{$this};
        width: 100%; // margin-right: 1%;
        @include breakpoint($bp-tablet) {
            width: auto;
            text-align: center;
            margin: 0;
            padding: 5px;
        }
    }
    &--carry{
        @extend #{$this};
        width: 100%; // margin-right: 1%;
        @include breakpoint($bp-tablet) {
            width: auto;
            text-align: center;
            margin: 0;
            padding: 5px;
        }
    }
    &--type{
        @extend #{$this};
        width:100%;
        display: flex;
        justify-content: space-between;
        flex-flow: row nowrap;
        border-radius:4px;
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px dotted #aacede;
        @include breakpoint($bp-tablet) {
            display: table-cell;
            width: auto;
            margin: 0;
            padding: 5px;
            text-align: center;
        }
        >div{
            margin-right: auto;
            margin-left: 3px;
        }
    }
    &--status {
        @extend #{$this};
        width:100%;
        // margin-bottom: 5px;
        // padding-bottom: 5px;
        // border-bottom: 1px solid #cce1ea;
        @include breakpoint($bp-tablet) {
            width: auto;
            text-align: center;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid #ddd;
        }
    }
    &--operation {
        @extend #{$this};
        width:100%;
        // margin-bottom: 5px;
        // padding-bottom: 5px;
        // border-bottom: 1px solid #cce1ea;
        @include breakpoint($bp-tablet) {
            width: auto;
            text-align: center;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid #ddd;
        }
    }
    &--file {
        @extend #{$this};
        width:100%;
        // margin-bottom: 5px;
        // padding-bottom: 5px;
        // border-bottom: 1px solid #cce1ea;
        @include breakpoint($bp-tablet) {
            width: auto;
            text-align: center;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid #ddd;
        }
    }
    &--store {
        @extend #{$this};
        width: 50%;
        // margin-bottom: 5px;
        // padding-bottom: 5px;
        // border-bottom: 1px solid #cce1ea;
        @include breakpoint($bp-tablet) {
            width: auto;
            text-align: center;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid #ddd;
        }
    }
    &--place {
        @extend #{$this};
        width: 50%;
        // margin-bottom: 5px;
        // padding-bottom: 5px;
        // border-bottom: 1px solid #cce1ea;
        @include breakpoint($bp-tablet) {
            width: auto;
            text-align: center;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid #ddd;
        }
    }
    .cancelled &--number {
        background:#867254;
        @include breakpoint($bp-tablet) {
            // background:$is-cancel;
        }
    }
    .cancelled:hover &--number {
        @include breakpoint($bp-tablet) {
            background:#ffd969;
        }
    }

    .cancelled &--start, .cancelled &--end {
        border-bottom: 1px solid #d49333;
        @include breakpoint($bp-tablet) {
            border-bottom: 1px solid #ddd;
        }
    }
}

.carry-status{
    padding: 1px 5px;
    background:#6db59a;
    color:#fff;
    border-radius:3px;
    vertical-align: middle;
    font-weight: bold;
    font-size: 12px;
    font-size: 1.2rem;
    text-align: center;
    min-width: 5em;
    display: inline-block;
    &.notyet{
        background:red;
    }
    &.nouse{
        background:#999;
    }
}

.label-worker{
    $this: &;
    background:$dusty-gray;
    color:$white;
    border-radius:2px;
    display:inline-block;
    min-width: 50px;
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: 500;
    text-shadow:0 1px 1px rgba(0,0,0,0.1);
    vertical-align: text-top;
    text-align: center;
    &-0{
        @extend #{$this};
        background:#86d6b8;
    }
    &-1{
        @extend #{$this};
        background:#86a2d6;
    }
    &-2{
        @extend #{$this};
        background:#c5d686;
    }
    &.disabled{
        background:rgba(0,0,0,0.4);
        color:rgba(255,255,255,0.4);
    }
}

.label-application-status{
    $this: &;
    background:$dusty-gray;
    color:$white;
    border-radius:2px;
    display:inline-block;
    min-width: 75px;
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: 500;
    vertical-align: text-top;
    text-align: center;
    &-0{
        @extend #{$this};
        background:#86b8d6;
    }
    &-1{
        @extend #{$this};
        background:#b486d6;
    }
    &-2{
        @extend #{$this};
        background:#d68a86;
    }
    &-3{
        @extend #{$this};
        background:#000000;
    }
}

.label-operation{
    $this: &;
    background:$alto;
    color:$white;
    border-radius:2px;
    display:inline-block;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    line-height: 19px;
    vertical-align: text-top;
    &-0{
        @extend #{$this};
        &.enabled{
            background:#86add6;
        }
    }
    &-1{
        @extend #{$this};
        &.enabled{
            background:#d68686;
        }
    }
    &-2{
        @extend #{$this};
        &.enabled{
            background:#b486d6;
        }
    }
}

.berth__date,
.berth__first,
.berth__second,
.berth__third,
.searchHistory__cardDate1,
.searchHistory__cardDate2,
.searchHistory__cardStore,
.searchHistory__cardPlace,
.searchHistory__carry{
    display: inline-block;
    width: 6em;
    margin-right: 3px;
    margin-bottom: 5px;
    text-align: center;
    color: #578398;
    background: #c4e1ef;
    font-size: 10px;
    font-size: 1.0rem;
    font-weight: bold;
    vertical-align: middle;
    position: relative;
    border-radius: 2px;
    top: 2px;
    @include breakpoint($bp-tablet) {
        display: none;
    }
    .cancelled &{
        background:#ffd9a0;
        color:#905e15
        ;
        @include breakpoint($bp-tablet) {
        }
    }
}

// .searchHistory__cardDate{
//   display:inline-block;
//   width: 98%;
//   text-align: center;
//   color:#578398;
//   background:#c4e1ef;
//   font-size: 10px;
//   font-size: 1.0rem;
//   font-weight: bold;
//   vertical-align: top;
// }
.searchHistory__cardNumber {
    color: $white;
    padding: 2px 5px;
    font-weight: bold;
    font-size: 10px;
    font-size: 1.0rem;
    @include breakpoint($bp-tablet) {
        display: none;
    }
    &:after {
        content: ":";
        margin-left: 3px;
    }
}

.berth-list-tbody-tr,
.construction-list-tbody-tr {
    position: relative;
    display: block;
    width: 100%;
    background: #fff;
    margin: 0 0 15px 0;
    padding: 8px;
    border-radius: 3px;
    box-shadow: 0 2px 1px #b5cbd6;
    transition: all ease 0.2s;
    cursor:pointer;
    @include clearfix();
    @include breakpoint($bp-tablet) {
        position:static;
        display: table-row;
        padding: 0;
        margin: 0;
        background: none;
        border-radius: 0;
        box-shadow: none;
        &:before,
        &:after {
            display: none;
        }
        &:hover {
            background: #fffbd4;
            cursor: pointer;
        }
        &:active {
            background: #fff165;
        }
    }
    &:nth-child(even) {
        @include breakpoint($bp-tablet) {
            background: #f8f9f9;
            &:hover {
                background: #fffbd4;
                cursor: pointer;
            }
            &:active {
                background: #fff165;
            }
        }
    }
    // &.cancelled{
    //     background:$is-cancel;
    //     @include breakpoint($bp-tablet) {
    //         border:1px solid #e89c2a;
    //     }
    // }
    // &.cancelled:hover{
    //     @include breakpoint($bp-tablet) {
    //         background:#ffd969;
    //     }
    // }
    &.editable{
        padding-left:40px;
    }
}

.berth__conditions,
.searchHistory__conditions {
    @extend %box-gray;
}

.searchHistory__form {
    width: 100%;
    @include breakpoint($bp-tablet) {
        margin-bottom: 10px;
    }
    tr {
        display: block;
        width: 100%;
        @include breakpoint($bp-tablet) {
            display: table-row;
            width: auto;
        }
    }
    th {
        display: block;
        width: 100%;
        text-align: left;
        @include breakpoint($bp-tablet) {
            display: table-cell;
            width: 12%;
            padding: 5px 8px;
        }
    }
    td {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        @include breakpoint($bp-tablet) {
            display: table-cell;
            width: 38%;
            padding: 2px 8px;
        }
    }
    .searchHistory__formBodyCell--fromto {
        #date_from {
            width: 49%;
            @include breakpoint($bp-tablet) {
                width: 140px;
                margin-right: 10px;
            }
        }
        #date_to {
            width: 49%;
            margin-left: 2%;
            @include breakpoint($bp-tablet) {
                width: 140px;
                margin-right: 10px;
            }
        }
    }
}

a.button.searchHistory__editTime {
    width: 25vw;
    float: right;
    padding: 0;
}

.kids__list {
    >span {
        display: inline-block;
        margin-right: 5px;
        &:first-child:before {
            content: "";
        }
        &:before {
            content: "/";
            margin-right: 5px;
        }
        &:after {
            content: "歳";
        }
    }
}

.searchHistory__icon{
    &--cancelled{
        // background:$is-cancel;
        font-size: 1.4rem;
        font-size: 14px;
        display: inline-block;
        margin: 0 0 10px 0;
        padding: 3px 8px;
        width: 100%;
        text-align:center;
        font-weight: bold;
        @include breakpoint($bp-tablet) {
            position: absolute;
            top: 0;
            right: 0;
            width: 120px;
        }
    }
}
