﻿.totop {
    font-size: 40px;
    font-size: 4.0rem;
    position: fixed;
    bottom: 45px;
    right: 5px;
    transition: all ease 0.3s;
    z-index: 25;
    opacity: 0.75;
    transform: translateX(100px);
    &.is-show {
        transform: translateX(0px);
    }
    .totop__link {
        display: block;
        width: 50px;
        height: 50px;
        text-decoration: none;
        color: #333;
        .totop__icon {
            display: block;
            text-align: center;
        }
    }
}
