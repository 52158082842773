﻿// Colors
//
// カラーの定義を行います。
//
// Markup:
// <div style="background: {$modifiers};" class="styleguide-color">{$modifiers}</div>
//
// Styleguide 1.5.0
$white             : #fff;
$black             : #000;
$bkg-body-gray     : #efefef;
$is-na             : #e6e6e6;
$is-cancel         : #ffc107;
$is-reserve        : #ff9c9c;
$is-others         : #cbf1d3;
$is-applying       : #5e95ed;
$is-suspend        : #607d8b;

$main-color        : #03a9f4;

/**/

$white             : #fff;
$bright-gray       : #fafafa;
$alabaster         : #F8F8F8;
$wildsand          : #f6f6f6;
$extra-bright-gray : #f3f3f3;
$extra-light-gray  : #eee;
$alto              : #ddd;
$silver            : #ccc;
$light-gray        : #aaa;
$dusty-gray        : #999;
$gray              : #808080;
$dim-gray          : #666;
$emperor           : #555;
$bkg-dark          : #4f4f4f;
$tundora           : #444;
$dark-gray         : #333;
$black             : #000;

$twilight-blue     : #f7fdff;
$pattens-blue      : #e0efff;
$mystic            : #e1ebec;
$aqua-haze         : #edf3f5;
$geyser            : #CEDADE;
$periwinkle-gray   : #BECDE0;
$pigeon-post       : #B0C6DE;
$danube            : #6697d6;
$egg-blue          : #00c2d7;
$cerulean          : #03a9f4;
$blue              : #2196f3;
$allports          : #0073a7;
$blumine           : #215F7B;
$slate-gray        : #607d8b;
$glacier           : #72ABC1;
$lynch             : #637190;
$fiord             : #414e6d;
$biscay            : #1b3a61;

$blue-marguerite   : #8073CA;

$deco              : #C2D8A0;
$fringy-flower     : #b7e4c7;
$olivine           : #A2C172;
$silver-tree       : #72C1A4;
$fern              : #70b764;
$light-green       : #00af68;
$persian-green     : #009688;
$green-pea         : #1e6537;
$turtle-green      : #273A0B;

$wisteria          : #bd72c1;
$strikemaster      : #8B6079;

$tawny-port        : #692745;

$melanie           : #efd9e3;
$cavern-pink       : #e0c0be;
$beryl-green       : #e0dcbe;
$twine             : #c19e72;
$copper-field      : #d68b66;
$contessa          : #c17272;
$charm             : #D66685;
$brown             : #8a4c00;
$burnt-umber       : #8c2922;
$red               : #f00;
$thunderbird       : #b91414;

$sidecar           : #f3ddc2;
$travertine        : #fffde7;
$pale-prim         : #FEF7BA;
$supernova         : #FFCA00;
$husk              : #b1a657;
$saratoga          : #585010;
