﻿.menu {
    position: absolute;
    top: 0;
    right: 0;
    @include breakpoint($bp-tablet) {
        display: none;
    }
    .menu__toggle {
        width: 44px;
        height: 44px;
        font-size: 22px;
        font-size: 2.2rem;
        padding: 0;
        text-align: center;
        display: block;
        background: none;
        border: none;
        line-height: 1.2;
        background: #03a9f4;
        border-radius: 0;
        float: right;
        position: relative;
        text-align: center;
        i {
            color: #fff;
            position: relative;
            top: -6px;
            @include breakpoint($bp-tablet) {
                top: -5px;
            }
        }
        span {
            color: $white;
            font-size: 9px;
            font-size: 1.0rem;
            position: absolute;
            bottom: 3px;
            left: 0;
            width: 100%;
            display: block;
            text-align: center;
        }
    }
}

.gnavi {
    position: fixed;
    top: -800px;
    z-index: 15000;
    width: 100%;
    transition: all ease 0.3s;
    @include breakpoint($bp-tablet) {
        position: relative;
        top: 0;
        margin-top: 44px;
        z-index: 99;
    }
    &.open {
        top: 44px;
    }
}

.gnavi__wrapper {
    margin: 0;
    padding: 0;
    height: 75vh;
    overflow:scroll;
    @include breakpoint($bp-tablet) {
        width: $site-width;
        margin: 0 auto;
        font-size: 0;
        height: auto;
        overflow:auto;
    }
}

.gnavi__submenuItem,
.gnavi__item {
    @include breakpoint($bp-tablet) {
        font-size: 16px;
        font-size: 1.6rem;
        display: inline-block;
        width: 32%;
        margin-left: 2%;
    }
    &:first-child() {
        @include breakpoint($bp-tablet) {
            margin-left: 0%;
        }
    }
    >a {
        border-bottom: 1px solid #ccc;
        padding: 10px 18px;
        background: #fff;
        color: #333;
        display: block;
        text-decoration: none;
        font-size: 16px;
        font-size: 1.6rem;
        transition: all ease 0.3s;
        @include breakpoint($bp-tablet) {
            font-weight: bold;
            padding: 18px;
        }
        &:hover {
            @include breakpoint($bp-tablet) {
                opacity: 0.7;
                color: #083f58;
                border-bottom: 1px solid #03a9f4;
            }
        }
        >i.fa {
            margin-right: 5px;
            color: $main-color;
            @include breakpoint($bp-tablet) {
                font-weight: normal;
                font-size: 24px;
                font-size: 2.4rem;
                vertical-align: bottom;
            }
        }
        >i.icon-kids{
            margin-right: 5px;
            &:before{
                content:"";
                background: #03a9f4 url(../img/symbol/kids.svg) center center;
                background-size:100%;
                width: 14px;
                height: 14px;
                border-radius: 2px;
                display: inline-block;
                vertical-align: bottom;
            }
        }
    }
    .toggle-accordion:after{
        color:#03a9f4;
    }
}

.gnavi__submenuItem{
    >a{
        padding-left: 30px;
        background: #f3f3f3;
    }
}

#overlay {
    transition: all ease 0.3s;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 50;
    top: 0;
    &.is-hide {
        opacity: 0;
    }
}
