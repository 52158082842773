﻿// Mixin
//
// Mixinを定義します。
//
// Styleguide 1.3.0
@mixin clearfix() {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

@mixin button ($color: #fff, $bkg-color: #bc1815) {
    color: $color;
    text-decoration: none;
    background: $bkg-color;
    border: none;
    margin-right: 0;
    position: relative;
    font-size: 14px;
    font-weight: bold;
    -webkit-user-select: none;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px, rgba(0, 0, 0, 0.2) 0px -3px 1px inset;
    border-radius: 3px; // text-shadow:rgba(0, 0, 0, 0.7) 0px 0px 5px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    min-height: 27px;
    box-sizing: border-box;
    line-height: 1.8;
    outline: none;
    padding: 8px 14px;
    width: 100%;
    @include breakpoint($bp-tablet) {
        // padding: 0px 14px;
        width: auto;
    }
    &:link,
    &:visited,
    &:hover,
    &:active {
        color: $color;
        text-decoration: none;
    }
    &:hover {
        opacity: 0.9;
        border: none;
    }
    &:active {
        position: relative;
        top: 1px;
        box-shadow: inset 0px -0px 1px rgba(255, 255, 255, 1), inset 0 0px 3px rgba(0, 0, 0, 0.9);
        outline: none;
    }
    &[disabled] {
        box-shadow: none;
        background: #ccc;
        cursor: not-allowed;
        &:hover {
            opacity: 1;
        }
    }
}

@mixin placeholderColor($color) {
    &:placeholder-shown {
        color: $color;
    }
    &::-webkit-input-placeholder {
        color: $color;
    }
    &:-moz-placeholder {
        color: $color;
        opacity: 1;
    }
    &::-moz-placeholder {
        color: $color;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color: $color;
    }
}

@mixin ellipsis () {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
