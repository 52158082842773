﻿#login {
    // background: $bkg-body-gray;
}

.loginForm__logo {
    @extend %form-logo;
}

.loginForm__lead {
    @extend %form-lead;
}

.loginForm__input {
    margin: 0;
    padding: 0;
    @include breakpoint($bp-tablet) {
        width: 65%;
        margin: 0 auto;
    }
}

.loginForm__item {
    margin-top: 5px;
    >input {
        height: 38px;
        padding: 10px;
    }
}

.loginForm__messageList--error {
    padding: 10px;
    border: 1px solid #ffb9b9;
    background: #fff0f0;
    color: red;
    margin: 0;
    left: 0;
}

.loginForm__button {
    margin: 20px auto 20px;
    text-align: center;
    @include breakpoint($bp-tablet) {
        width: 35%;
        margin: 20px auto;
    }
    >button {
        @extend %form-button;
    }
}

.loginForm__forgotten {
    text-align: center;
}

.loginForm__newreg {
    border-top: 1px dotted #ccc;
    text-align: center;
    p{
        a{
            color:red;
        }
    }
}
