.page-title{
    @extend %h1-heading;
    @include breakpoint($bp-tablet) {
    }
}

.check-list--hr{
    label{
        margin-right: 1.5rem;
        input{
            margin-right: 5px;
            vertical-align: middle;
        }
    }
}

.toggle-group{
    th{
        background:#c5d7e0;
    }
}

.toggle-group + tr > th{
    display: none;
    @include breakpoint($bp-tablet) {
        display: table-cell;
    }
}

.error-message{
    color: red;
}

.error-message-list{
    padding: 10px;
    border: 1px solid #ffb9b9;
    background: #fff0f0;
    color: red;
    margin: 0;
    left: 0;
}