﻿%loader-base {
    top: 0px;
    left: -1px;
    width: calc(100% + 1px);
    height: calc(100% + 5px);
    z-index: 20000;
    background: #eaf4f9;
}

#loader,
#loader-content {
    @extend %loader-base;
    position: fixed;
}

#loader {
    z-index: 20001;
}

#loader-content {
    @extend %loader-base;
   
    position:fixed;
    width:100%;
    height:calc(100% - 44px);
    top:44px;
    left:0;
    z-index:20000;
    background: #fff;

    @include breakpoint($bp-tablet) {
        position: absolute;
    }

    .loader__symbol {
        top: 150px;
        bottom: auto;
    }
}

.use-loader {
    .loader-wrapper {
        display: block;
    }
}

.loader {
    height: 50px;
    width: 50px;
    margin: auto 0 auto -25px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
}

.loader__symbol {
    animation: rotate 3s infinite;
    height: 50px;
    width: 50px;
    margin: auto 0 auto -25px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
}

.loader__symbol:before,
.loader__symbol:after {
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
}

.loader__symbol:before {
    margin-bottom: 10px; // animation: ball1 1s infinite;
    background-color: #60CEFF;
    box-shadow: 30px 0 0 #F66A97;
}

.loader__symbol:after {
    // animation: ball2 1s infinite; 
    background-color: #60c5ba;
    box-shadow: 30px 0 0 #ffc300;
}

.loader__msg {
    width: 100%;
    position: absolute;
    text-align: center;
    top: 50%;
    margin-top: 30px;
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg) scale(0.5);
        -moz-transform: rotate(0deg) scale(0.5);
        -ms-transform: rotate(0deg) scale(0.5);
    }
    50% {
        -webkit-transform: rotate(360deg) scale(0.8);
        -moz-transform: rotate(360deg) scale(0.8);
        -ms-transform: rotate(360deg) scale(0.8);
    }
    100% {
        -webkit-transform: rotate(720deg) scale(0.5);
        -moz-transform: rotate(720deg) scale(0.5);
        -ms-transform: rotate(720deg) scale(0.5);
    }
}

@keyframes ball1 {
    0% {
        box-shadow: 30px 0 0 red;
    }
    50% {
        box-shadow: 0 0 0 red;
        margin-bottom: 0;
        -webkit-transform: translate(15px, 15px);
        -moz-transform: translate(15px, 15px);
        -ms-transform: translate(15px, 15px);
    }
    100% {
        box-shadow: 30px 0 0 red;
        margin-bottom: 10px;
    }
}

@keyframes ball2 {
    0% {
        box-shadow: 30px 0 0 #ffc300;
    }
    50% {
        box-shadow: 0 0 0 #ffc300;
        margin-top: -20px;
        -webkit-transform: translate(15px, 15px);
        -moz-transform: translate(15px, 15px);
        -ms-transform: translate(15px, 15px);
    }
    100% {
        box-shadow: 30px 0 0 #ffc300;
        margin-top: 0;
    }
}
