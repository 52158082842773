﻿.button-set {
    &.bottom {
        margin-top: 10px;
    }
    .button {
        display: inline-block;
        &.fr {
            margin-left: 10px;
        }
    }
}

.button,
.button:link {
    @include button;
    i.fa {
        margin-right: 5px;
        // margin-left: 5px;
    }
}

.button.is-sm{
    padding: 4px 10px 5px !important;
    font-weight: 500 !important;
    line-height: 1.4 !important;
}

.button.square {
    padding: 0 !important;
    width: 27px;
    height: 27px;
}

.button.disabled {
    background: #ccc !important;
    box-shadow: none !important;
    &:hover {
        cursor: not-allowed !important;
        opacity: 1 !important;
    }
    &:active {
        top: 0 !important;
    }
}

.button.red {
    @include button($white, #bc1815);
}

.button.white {
    @include button(#666, #f3f3f3);
}

.button.slate-gray {
    @include button($white, $slate-gray);
}

.button.gray {
    @include button($white, $dim-gray);
}

.button.dark-gray {
    @include button($white, $dark-gray);
}

.button.blue {
    @include button($white, $main-color);
}

.button.forest-green {
    @include button($white, $light-green);
}

.button.lynch {
    @include button($white, $lynch);
}

%button_list {
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
}

.buttonset {
    margin: 20px 15px 0 15px;
    @include breakpoint($bp-tablet) {
        margin: 20px 0 0;
    }
    @include clearfix();
    .button{
        margin-bottom: 10px;
        @include breakpoint($bp-tablet) {
            margin-bottom: 0;
        }
    }
    .button:first-child {
        margin-bottom: 10px;
        @include breakpoint($bp-tablet) {
            margin-bottom: 0;
        }
    }
    .right{
        @include breakpoint($bp-tablet) {
            float:right;
            margin-left: 10px;
        }
    }
    .left{
        @include breakpoint($bp-tablet) {
            float:left;
            margin-right: 10px;
        }
    }
    &.fixed{
        position: fixed;
        bottom: 48px; /*footer height*/
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 10px 15px 0 15px;
        background:rgba(255,255,255,0.85);
        box-shadow:0 0 5px rgba(0,0,0,0.09);
        z-index: 100;
        @include breakpoint($bp-tablet) {
            position: relative;
            bottom: 0;
            width: auto;
            margin: 20px 0 0;
            padding: 0;
            box-shadow: none;
        }
    }
}
