﻿.for-sp {
    display: block !important;
}

span.for-sp,
i.for-sp {
    display: inline !important;
}

.for-pc {
    display: none !important;
}

span.for-pc,
i.for-pc {
    display: none !important;
}

@include breakpoint($bp-tablet) {
    .for-sp {
        display: none !important;
    }
    span.for-sp,
    i.for-sp {
        display: none !important;
    }
    .for-pc {
        display: block !important;
    }
    span.for-pc,
    i.for-pc {
        display: inline !important;
    }
}

// position
//
// positionプロパティを個別に指定します。<br>※表示が崩れるためサンプルは省略。<br>
// <br>
//　.pos-s - static<br>
//　.pos-r - relative<br>
//　.pos-a - absolute<br>
//　.pos-f - fixed
//
// Styleguide 4.2.0
.pos-s {
    position: static !important;
}

.pos-r {
    position: relative !important;
}

.pos-a {
    position: absolute !important;
}

.pos-f {
    position: fixed !important;
}

// align
//
//　.tl - 左寄せ
//　.tc - 中央寄せ
//　.tr - 右寄せ
//
// Markup:
// <div class="{$modifiers}">ダミーテキストダミーテキスト</div>
//
// Styleguide 4.3.0
.tl {
    text-align: left !important;
}

.tc {
    text-align: center !important;
}

.tr {
    text-align: right !important;
}

// float
//
// 使用時は弟要素でclearするか、親要素でclearfixしましょう。
//
//　.fl - float:left
//　.fr - float:right
//
// Markup:
// <div class="{$modifiers}">ダミーテキストダミーテキスト</div>
//
// Styleguide 4.4.0
.fl {
    float: left !important;
}

.fr {
    float: right !important;
}

// hide
//
// 要素を非表示にする場合に使用します。<br>
// ※cssスタイルを直接弄るより、classを切り替える方が低コスト。
//
//　.hide - display:none;
//
// Markup:
// <div class="{$modifiers}">ダミーテキストダミーテキスト</div>
//
// Styleguide 4.5.0
.is-hide,
.hide {
    display: none !important;
}

.invisible {
    position: absolute !important;
    z-index: -1 !important;
    width: 0 !important;
    height: 0 !important;
    opacity: 0 !important;
}

// width (%)
//
// 要素の幅を個別に%で指定する場合に使用します。
// 0～100%まで5%刻みで指定可能です。
//
//　.w-25 - width:25%;
//　.w-50 - width:50%;
//　.w-75 - width:75%;
//　.w-100 - width:100%;
//
// Markup:
// <div style="background:#eee;" class="{$modifiers}">ダミーテキストダミーテキスト</div>
//
// Styleguide 4.6.0
@for $i from 0 through 20 {
    .w-#{$i * 5} {
        width: percentage($i * 5 / 100) !important;
    }
}

// width (em)
//
// 要素の幅を個別にemで指定する場合に使用します。
// 例としてtableの列幅などへの使用が挙げられます。<br>
// 0～20emまで1em刻みで指定可能です。
//
//　.w-5em - width:5em;
//　.w-10em - width:10em;
//　.w-15em - width:15em;
//　.w-20em - width:20em;
//
// Markup:
// <div style="background:#eee;" class="{$modifiers}">ダミーテキストダミーテキスト</div>
//
// Styleguide 4.7.0
@for $i from 0 through 20 {
    .w-#{$i}em {
        width:#{$i}em !important;
    }
}

// margin (px)
//
// 要素の上下の余白（margin/padding）を個別に指定したい場合に使用します。<br>
// 0～100pxまで5px刻みで指定可能です。
//
//　.mb-20 - margin-bottom:20px;
//　.mt-20 - margin-top:20px;
//　.mr-20 - margin-right:20px;
//　.ml-20 - margin-left:20px;
//
// Markup:
// <div style="background:#eee;" class="{$modifiers}">ダミーテキストダミーテキスト</div>
//
// Styleguide 4.8.0
@for $i from 0 through 20 {
    .mb-#{$i * 5} {
        margin-bottom:#{$i * 5}px !important;
    }
    .mt-#{$i * 5} {
        margin-top:#{$i * 5}px !important;
    }
    .mr-#{$i * 5} {
        margin-right:#{$i * 5}px !important;
    }
    .ml-#{$i * 5} {
        margin-left:#{$i * 5}px !important;
    }
}

// padding (px)
//
// 要素の上下の余白（margin/padding）を個別に指定したい場合に使用します。<br>
// 0～100pxまで5px刻みで指定可能です。
//
//　.pb-20 - padding-bottom:5px;
//　.pt-20 - padding-top:20px;
//　.pr-20 - padding-right:20px;
//　.pl-20 - padding-left:20px;
//
// Markup:
// <div style="background:#eee;" class="{$modifiers}">ダミーテキストダミーテキスト</div>
//
// Styleguide 4.9.0
@for $i from 0 through 20 {
    .pb-#{$i * 5} {
        padding-bottom:#{$i * 5}px !important;
    }
    .pt-#{$i * 5} {
        padding-top:#{$i * 5}px !important;
    }
    .pr-#{$i * 5} {
        padding-right:#{$i * 5}px !important;
    }
    .pl-#{$i * 5} {
        padding-left:#{$i * 5}px !important;
    }
}

// indent
//
// スマホ表示時など、左右100%表示ではなく、余白を持たせる場合に使用します。
//
// Markup:
// <div class="indent">ダミーテキストダミーテキスト</div>
//
// Styleguide 4.10.0
.indent {
    padding-left: 15px;
    padding-right: 15px;
    @include breakpoint($bp-tablet) {
        // padding-left: 0;
        // padding-right: 0;
    }
    &--pc {
        @include breakpoint($bp-tablet) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.indent-vert {
    padding-top: 15px;
    padding-bottom: 15px;
    @include breakpoint($bp-tablet) {
    }
    &--pc {
        @include breakpoint($bp-tablet) {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
}

.of-h {
    overflow: hidden !important;
}

.dp{
    &-b{
        display:block;
    }
    &-ib{
        display:inline-block;
    }
    &-tb{
        display:table;
    }
}

