﻿%table-input {
    width: 100%;
    display: block;
    @include breakpoint($bp-tablet) {
        display: table;
        border-collapse: collapse;
    }
    tbody {
        width: 100%;
        display: block;
        @include breakpoint($bp-tablet) {
            display: table-row-group;
        }
    }
    tr {
        display: block;
        @include breakpoint($bp-tablet) {
            display: table-row;
        }
    }
    th {
        width: 100%;
        display: block;
        text-align: left;
        background: #e5f1f7;
        padding: 8px 8px;
        position: relative;
        border-bottom: 1px solid #a3bbc7;
        position: relative;
        @include breakpoint($bp-tablet) {
            width: auto;
            display: table-cell;
            border-bottom: 1px solid $white;
        }
    }
    td {
        width: 100%;
        display: block;
        padding: 6px 10px;
        @include breakpoint($bp-tablet) {
            width: auto;
            display: table-cell;
            border-top: 1px solid #e3e3e3;
            border-bottom: 1px solid #e3e3e3;
        }
    }
}

.c-table{
    width: 100%;
    border-collapse: collapse;
    tr{
        display: table-row;
        th{
            display: table-cell;
        }
        td{
            display: table-cell;
        }
        &.bkg-red{
            td{
                background:#ffc6c6;
            }
        }
    }
    &.with-button{
        width: calc(100% - 50px);
    }
}


.toggle-group{
    cursor:pointer;
    &.is-active{
        .toggle-group__icon{
            transform:rotate(-180deg);
        }
    }
    &__icon{
        font-size: 24px;
        font-size: 2.4rem;
        display: block;
        position: absolute;
        right: 5px;
        top: 50%;
        width: 24px;
        height: 24px;
        margin-top: -12px;
        transition:all ease 0.4s;
        transform:rotate(0);
        i.fa{
            display: block;
            text-align: center;
        }
    }
}

.table-result{
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    box-shadow:0 1px 4px rgba(24, 28, 33, 0.009);
    thead{
        background:#666;
        th{
            border-left: 1px solid #999999;
            border-right: 1px solid #999999;
            font-weight: 400;
            font-size: 13px;
            font-size: 1.3rem;
            a{
                color:$white;
            }
        }
    }
    tbody{
        tr{
            &:hover{
                cursor:pointer;
                td{
                    background:#f9ffe7;
                }
            }
            td{
                padding: 2px 5px;
                background:$white;
                border: 1px solid #eeeeee;
                transition:all ease 0.15s;
            }
        }

    }
}

.result-info{
    span{
        font-weight: bold;
        color:#cc3d3d;
        font-size: 18px;;
        font-size: 1.8rem;
    }
    thead{
        th{
            background:#3a3f48;
        }
    }
}

.card.conditions{
    .card-body{
        padding: 0.2rem;
    }
}

.table-conditions{
    width: 100%;
    border-collapse: separate;
    th{
        background:#666;
        color:$white;
        padding: 2px 10px;
        border-radius: 2px;
        height: 44px;
        font-weight: 500;
    }
    td{
        padding: 2px 10px;
    }
}

.table-condition-btn{
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -22px;
}

.account-form,
.input-table{
    @extend %table-input;
    width: 100%;
    border-collapse: collapse;
    // margin-bottom: 15px;
    th{
        @include breakpoint($bp-tablet) {
            width: 250px;
            padding: 3px 10px 0 15px;
            // background:#f3f3f3;
            border-bottom: 1px solid #fcfcfc;
            position: relative;
            text-align: left;
        }
    }
    td{
        padding: 10px 15px;
        border-bottom: 1px solid #eee;
        @include breakpoint($bp-tablet) {
            height: 47px;
            padding: 3px 8px;
        }


    }
}

// .row-sub{
//     @include breakpoint($bp-tablet) {
//     }
//     th{
//         background: #eaeaea;
//     }
// }

.table-sub{
    $this: &;
    @extend %table-input;
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    th{
        background:#dbe3e8;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        padding: 5px;
        font-size: 12px;
        font-size: 1.2rem;
        @include breakpoint($bp-tablet) {
            padding: 8px;
            font-size: 14px;
            font-size: 1.4rem;
        }
    }
    td{
        border: none;
        background:#eaeef1;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        padding: 5px;
        font-size: 12px;
        font-size: 1.2rem;
        @include breakpoint($bp-tablet) {
            padding: 8px;
            font-size: 14px;
            font-size: 1.4rem;
        }
    }
    &--worker{
        @extend #{$this};
        th{
            @include breakpoint($bp-tablet) {
                width: 10em !important;
            }
        }
    }
    &--tenant{
        @extend #{$this};
        th{
            @include breakpoint($bp-tablet) {
                width: 10em !important;
            }
        }
    }
    &--place{
        @extend #{$this};
        th{
            @include breakpoint($bp-tablet) {
                width: 6em !important;
            }
        }
    }
}
