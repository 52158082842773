﻿.required {
    padding: 0px 5px 1px;
    border-radius: 3px;
    background: #b91414;
    color: #fff;
    font-size: 11px;
    font-size: 1.1rem;
    font-weight: bold;
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: -8px;
}
