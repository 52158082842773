﻿#reservation {
    #time_from {
        width: 5em;
    }
}

.result-meta__number {
    margin-top: 6px;
    float: left;
}

.result-meta__sort {
    margin-bottom: 5px;
    float: right;
}

.result-meta__example {
    float:left;
    background:#f4f4f4;
    margin: 0px 0 5px 10px;
    padding: 7px 6px;
    font-size: 1.1rem;
    font-size: 11px;
    @include breakpoint($bp-tablet) {
        margin: 0px 0 0 15px;
        padding: 7px 8px;
    }
    &__item--cancel{
        &:before{
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            box-sizing: border-box;
            vertical-align: middle;
            margin-right: 5px;
            position: relative;
            top: -1px;
            // background:$is-cancel;
            @include breakpoint($bp-tablet) {
            }
        }
    }
}
