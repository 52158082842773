﻿/* ログイン/リマインダー/パスワード再設定 */

//ロゴ
%form-logo {
    margin: 5px 0 25px 0;
    color: #e6feff;
    background: url(../img/logo-sp.png) center center / contain no-repeat;
    text-indent: -9999px;
    height: 40px;
    width: 100%;
    display: inline-block;
}

//リード文
%form-lead {
    text-align: center;
    margin-bottom: 20px;
    line-height: 1.7;
}

//submitボタン
%form-button {
    width: 100% !important;
    padding: 15px 0 !important;
}


/* お知らせ */

//NEW アイコン
%icon-new {
    background: #e60707;
    color: $white;
    border-radius: 3px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    font-size: 10px;
    font-size: 1.0rem;
    line-height: 1.6;
    padding: 1px 5px 0px;
}

%indent {
    padding-left: 15px;
    padding-right: 15px;
}


/* 汎用 */

//背景グレーボックス
%box-gray {
    margin-bottom: 15px;
    padding: 10px;
    background: #eee;
    border-radius: 3px;
}
