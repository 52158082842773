// Side
//
// サイドバー部分を定義します。
//
// Markup:
//<div id="content" class="clear">
// <aside id="side" class="open">
// 	<ul class="menu">
// 		<li class="">
// 				<a href="#"><i class="fa fa-fw fa-gear"></i>dummy</a>
// 				<i class="toggle-sub-menu fa fa-chevron-circle-down"></i>
// 				<ul class="sub-menu" style="display: none;">
// 					<li><a href="master-facility-list.html">dummy</a></li>
// 				</ul>
// 		</li>
// 		<li>
// 			<a href="#"><i class="fa fa-fw fa-wrench"></i>dummy</a>
// 		</li>
// 	</ul>
// 	<div class="toggle-menu"><a href="#"><i class="fa fa-chevron-left"></i></a></div>
// </aside>
// </div>
//
// Styleguide 2.4.0
#side {
    position: relative;
    width: 100%;
    @include breakpoint($bp-tablet) {
        width: $side-width;
        float: right;
    }
}

.sidebar__wrapper {
    margin: 0;
    padding: 0;
}

.sidebar__item {
    position: relative;
}

.sidebar__toggle {
    position: absolute;
    top: 12px;
    right: 10px;
    color: $white;
    font-size: 20px;
    font-size: 2.0rem;
}

.sidebar__title {
    background: #336f8a;
    padding: 10px 8px;
    font-size: 16px;
    font-size: 1.6rem;
    color: $white;
    white-space:nowrap;
    @include breakpoint($bp-tablet) {
        font-size: 14px;
        font-size: 1.4rem;
    }
    i.fa{
        margin-right: 8px;
    }
}

.sidebar__submenu {
    margin: 0;
    padding: 0;
}

.sidebar__submenuItem {
    >a {
        display: block;
        padding: 10px 15px;
        background: #d8f3ff;
        border-top: 1px solid #7bd6ff;
        color: #004f73;
        text-decoration: none;
        font-size: 14px;
        font-size: 1.4rem;
        transition: all ease 0.3s;
        &:hover {
            @include breakpoint($bp-tablet) {
                opacity: 0.75;
            }
        }
        >i.fa {
            margin-right: 8px;
        }
        >i.icon-kids {
            width: 18px;
            height: 14px;
            display: inline-block;
            margin-right: 8px;
            &:before{
                content:"";
                background: #004f73 url(../img/symbol/kids.svg) center center;
                background-size:100%;
                width: 14px;
                height: 14px;
                border-radius: 2px;
                display: inline-block;
                vertical-align: bottom;
            }
        }
    }
}

.sidebar__login {
    margin-bottom: 10px;
    background: #eee;
    padding: 10px;
    border-radius: 3px;
    text-align: center;
    >a.sidebar__mypageBtn.button {
        width: 100%;
        margin-bottom: 8px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

#side-logout-btn,
#side-login-btn {
    padding: 5px 14px;
    width: 100%;
}

.sidebar__loginBtn {
    margin-bottom: 10px;
}

.sidebar_forgotLink {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.2;
}

.sidebar__loginInfo {
    margin-bottom: 10px;
}

.sidebar__loginInfo__today {
    font-weight: bold;
    font-size: 18px;
    font-size: 1.8rem;
}

.banner__link{
    margin-top: 20px;
    @include breakpoint($bp-tablet) {
        margin-top: 10px;
    }
}

.banner__link-item{
    a{
        display: block;
        text-align: center;
        img{
            box-shadow:0 1px 5px rgba(0, 0, 0, 0.25);
            @include breakpoint($bp-tablet) {
                width: 100%;
            }
        }
    }
}

.toggle-accordion{
    position: relative;
    &:after{
        content:"\f13a";
        font-family:"FontAwesome";
        position: absolute;
        right: 8px;
        top: 50%;
        margin-top: -1.2rem;
        transition:all ease 0.2s;
    }
    &.active:after{
        transform: rotate(-180deg);
    }
}

.toggle-accordion + .sidebar__submenu{
    .sidebar__submenuItem{
        > a{
            padding-left: 15px;
            background: #c5e6f5;
            border-top: 1px solid #9cc5d8
            ;
        }
    }
}
