// Footer
//
// フッタを定義します。
//
// Markup:
// <footer>
// 	<div class="copyright">
// 		Copyright© Hitachi Zosen Corporation , Osaka, Japan. All Rights Reserved.
// 	</div>
// </footer>
//
// Styleguide 2.2.0
footer {
    width: 100%;
    border-top: 1px solid #03a9f4;
    position: absolute;
    bottom: 0;
    @include breakpoint($bp-tablet) {
        // min-height: 120px;
    }
    .pos-f &{
        position: relative;
    }
    .footer-wrapper{
        @include breakpoint($bp-tablet) {
            width: $site-width;
            margin: 0 auto;
            position: relative;
        }
    }
}

.copyright {
    padding: 5px 0;
    font-size: 10px;
    text-align: center;
    line-height: 1.5;
}

.banner__ssl{
    margin-top: 10px;
    text-align:center;
    @include breakpoint($bp-tablet) {
        position: absolute;
        top: 10px;
        right: 0;
        margin-top: 0;
    }
}

.footer__link{
    display:none;
    @include breakpoint($bp-tablet) {
        display: block;
        padding: 20px 0 0 0;
        text-align:center;
        font-size: 0;
    }
    &-item{
        @include breakpoint($bp-tablet) {
            display: inline-block;
            margin: 0 5px;
            font-size: 14px;
            font-size: 1.4rem;
        }
        &:after{
            @include breakpoint($bp-tablet) {
                content:"|";
                margin-left: 10px;
            }
        }
        &:last-child:after{
            @include breakpoint($bp-tablet) {
                content:"";
            }
        }
    }
}

#footer-navi{
    position: fixed;
    bottom: 0;
    width: 100%;
    background:#03a9f4;
    display: flex;
    flex-flow: row nowrap;
    box-shadow: 0 -4px 6px rgba(0,0,0,0.1);
    z-index: 20001;
    @include breakpoint($bp-tablet) {
        display: none;
    }
    li{
        flex:1 1;
        a{
            display:block;
            text-align: center;
            font-size: 1.2rem;
            color:#fff;
            height: 48px;
            line-height: 1.4;
            padding: 5px 0 0 0;
            .fa{
                display: block;
                font-size: 2.4rem;
            }
        }
    }
}

.footer-navi{
    &__newreg{
        
    }
}



