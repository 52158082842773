﻿.constructionForm {
    @extend %table-input;
    tbody {
        tr {
            th {
                @include breakpoint($bp-tablet) {
                    width: 220px;
                }
            }
        }
    }
}

.constructionForm{
    &__carry{
        .c-table{
            width: 100%;
            tbody{
                display: table-row-group;
            }
            th{
                width: 4em !important;
            }
            td{
                width: auto !important;
            }
            .vehicle{
                td{
                    input{
                        width: 4em;
                        text-align: right;
                    }
                }
            }
        }
    }
    &__memberNum{
        td{
            input{
                width: 5em;
                text-align: right;
            }
        }
    }
    &__guard{
        td{
            input{
                width: 5em;
                text-align: right;
            }
        }
    }
    &__operation{
        .c-table{
            width: 100%;
            tbody{
                display: table-row-group;
            }
            th{
                width: 6em/* !important*/;
            }
            td{
                width: auto !important;
            }
        }
    }
    &__noisy{
        th{
            cursor:pointer;
        }
    }
    &__special{
        table{
            width: 100%;
            font-size: 12px;
            font-size: 1.2rem;
            @include breakpoint($bp-tablet) {
                font-size: 14px;
                font-size: 1.4rem;
            }
            .amount{
                td{
                    input{
                        width: 4em;
                        margin-right: 5px;
                        &:first-child{
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
    &__fire{
        table{
            width: 100%;
            font-size: 12px;
            font-size: 1.2rem;
            @include breakpoint($bp-tablet) {
                font-size: 14px;
                font-size: 1.4rem;
            }
            .start-end{
                td{
                    input{
                        width: 5em;
                        &:first-child{
                            text-align: right;
                        }
                    }
                }
            }
            .amount{
                td{
                    input{
                        width: 4em;
                        margin-right: 5px;
                        &:first-child{
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
    &__danger{
        table{
            width: 100%;
            font-size: 12px;
            font-size: 1.2rem;
            @include breakpoint($bp-tablet) {
                font-size: 14px;
                font-size: 1.4rem;
            }
            tbody{
                display: table-row-group;
            }
            th{
                width: 7em !important;
            }
            td{
                width: auto !important;
            }
            .start-end{
                td{
                    input{
                        width: 5em;
                        &:first-child{
                            text-align: right;
                        }
                    }
                }
            }
            .amount{
                td{
                    input{
                        width: 4em;
                        margin-right: 5px;
                        &:first-child{
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}

.account__save {
    width: 100%;
    padding: 20px 0;
}

input[name="last_name"],
input[name="first_name"],
input[name="last_kana"],
input[name="first_kana"] {
    @include breakpoint($bp-tablet) {
        width: 50%;
    }
}

input[name="tel_number"]{
    width: 10em;
}

input[name="date_from"],
input[name="date_to"] {
    width: 8em;
}

#first_name-error,
#last_name-error,
#tel_number-error {
    @include breakpoint($bp-tablet) {
        margin-left: 10px;
    }
}

#phone-error,
#date_range-error {
    display: block;
    @include breakpoint($bp-tablet) {
        display: inline-block;
        margin-left: 10px;
    }
}

.label__radio {
    margin-right: 15px;
    input {
        margin-right: 5px;
    }
}

.subscribe{
    li{
        margin-bottom: 10px;
    }
    .title{
        margin-bottom: 3px;
        padding: 3px 5px;
        background:#dbe3e8;
    }
}
