﻿// Typography
//
// フォント関連の共通設定を定義します。
//
// Markup:
// <div>ダミーテキスト</div>
//
// Styleguide 1.6.0
html {
    font-size: 62.5%;
    line-height: 1.6;
}

body {
    // font-family: 'Lato', Calibri, Arial,'ヒラギノ角ゴ ProN W6', 'ヒラギノ角ゴ Pro W6','Hiragino Kaku Gothic Pro','メイリオ', Meiryo, 'ＭＳ Ｐゴシック','ＭＳ ゴシック', Osaka, sans-serif;
    font-family: 'ヒラギノ角ゴ ProN W6', 'ヒラギノ角ゴ Pro W6', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'ＭＳ ゴシック', Osaka, sans-serif;
    font-size: 1.4rem;
}

button,
input,
optgroup,
select,
textarea {
    // font-family: 'Lato', Calibri, Arial,'ヒラギノ角ゴ ProN W6', 'ヒラギノ角ゴ Pro W6','Hiragino Kaku Gothic Pro','メイリオ', Meiryo, 'ＭＳ Ｐゴシック','ＭＳ ゴシック', Osaka, sans-serif;
    font-family: 'ヒラギノ角ゴ ProN W6', 'ヒラギノ角ゴ Pro W6', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'ＭＳ ゴシック', Osaka, sans-serif;
}

a{
    &:link, &:visited{
        color:#006c9c;
        text-decoration: none;
    }
    &:hover, &:active{
        color:#008cca;
        text-decoration: underline;
    }

}
