﻿.c-label{
    background:#a8e6cf;
    color:#235845;
    text-align: center;
    border-radius: 4px;
    padding: 5px 0;
    margin-bottom: 12px;
    position: relative;
    &:after{
        content:"";
        position: absolute;
        bottom: -6px;
        left: 50%;
        margin-left: -10px;

        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 4px 0 4px;
        border-color: #a8e6cf transparent transparent transparent;
    }
}
