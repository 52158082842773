﻿// Pager
//
// Pagerの要素を定義します。
//
// Styleguide 3.6.0
.pagination {
    margin: 10px 0;
    text-align: center;
    > ul {
        display: inline-block;
        clear: both;
        padding: 0;
        white-space: nowrap;
        > li{
            display: inline;
            float: left;
            overflow: hidden;
            margin: 0;
            margin-left: -1px;
            padding: 0;
            &--prev,
            &--next {
            }
            &.is-current {
                >a {
                    cursor: default;
                    color: $white !important;
                    border: 1px solid $main-color;
                    background: $main-color;
                }
            }
            >a,
            span {
                font-size: 1.4rem;
                font-weight: normal;
                line-height: 36px;
                display: block;
                width: 36px;
                min-width: 1em;
                height: 36px;
                margin: 0;
                padding: 0;
                transition: all ease .3s;
                text-decoration: none;
                color: #666;
                border: 1px solid #999;
                // border-radius: 50%;
            }
        }
    }
}
