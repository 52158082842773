﻿// Checkbox （カスタム）
//
// TODO:Checkboxの説明
//
// Markup:
// <label for="cbx" class="c-check">
//   <input id="cbx" type="checkbox" class="invisible">
//   <div class="checkbox">
//     <svg width="20px" height="20px" viewBox="0 0 20 20">
//       <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path>
//       <polyline points="4 11 8 15 16 6"></polyline>
//     </svg>
//   </div>
//   <span>Checkbox</span>
// </label>
//
// Styleguide 3.12.0

.c-check{
  display:inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  margin-bottom: 0;
  input:checked{
    & + .checkbox{
      border-color: $cerulean ;
      svg{
        path{
          fill:$cerulean;
        }
        polyline{
          stroke-dashoffset: 0;
        }
      }
    }
  }
  &:hover{
    .checkbox{
      svg{
        path{
          stroke-dashoffset: 0;
        }
      }
    }
  }
  .checkbox{
    position:relative;
    top:3px;
    float:left;
    margin-right:6px;
    width: 20px;
    height: 20px;
    border:2px solid $silver;
    border-radius: 3px;
    svg{
      position: absolute;
      top: -2px;
      left: -2px;
      path{
        fill:none;
        stroke:$cerulean;
        stroke-width:2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 71px;
        stroke-dashoffset: 71px;
        transition: all .6s ease;
      }
      polyline{
        fill: none;
        stroke: $white;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 18px;
        stroke-dashoffset: 18px;
        transition: all .3s ease;
      }
    }
  }
  > span{
    pointer-events: none;
    vertical-align: middle;
  }
}
