﻿// Form
//
// Formの要素を定義します。
//
// Styleguide 3.5.0
// input / textarea
//
// 幅はutilityの.w-*、もしくはstyle属性のwidthを直接指定します。
//
// text - テキスト入力
// search - 検索入力
// number - 数値入力
// password - パスワード入力
//
// Markup:
// <input type="{$modifiers}" value="" placeholder="placeholder">
//
// Styleguide 3.5.1
@include placeholderColor(#aaa);
input[type="text"],
input[type="search"],
input[type="number"],
input[type="date"],
input[type="password"],
input[type="tel"],
input[type="email"],
input[type="time"],
textarea {
    width: 100%;
    // margin-top: 3px;
    // margin-bottom: 3px;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, .1);
    border: 1px solid $form-border-color;
    padding: 8px;
    height: 34px;
    font-size: 14px;
    font-size: 1.4rem;
    vertical-align: middle;
    -webkit-appearance: none;
    &[readonly] {
        background: #eee;
    }
    border-radius:$form-border-radius;
}

textarea {
    width: 100%;
    height: 4em;
}

input[type="number"] {
    text-align: right;
}

// readonly / disabled
//
// readonly - 読取専用
// disabled - 無効
//
// Markup:
// <input type="text" {$modifiers} value="value" placeholder="placeholder">
//
// Styleguide 3.5.1.1
input[type="text"],
input[type="search"],
input[type="number"],
input[type="password"],
input[type="time"],
textarea {
    &[readonly] {
        background: $extra-light-gray;
    }
    &[disabled] {
        background: $light-gray;
    }
    &.error {
        background: #ffe7e7;
    }
}

// select
//
// Markup:
// <select>
//   <option>option-1</option>
//   <option>option-2</option>
//   <option>option-3</option>
// <select>
//
// Styleguide 3.5.2
select {
    border-radius: $form-border-radius;
    min-height: 28px;
    vertical-align: middle;
    -webkit-appearance: none;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, .1);
    border: 1px solid $form-border-color;
    padding: 8px;
    height: 34px;
    width: 100%;
    option {}
}

// readonly / disabled
//
// readonly - 読取専用
// disabled - 無効
//
// Markup:
// <select {$modifiers}>
//   <option>option-1</option>
//   <option>option-2</option>
//   <option>option-3</option>
// <select>
//
// Styleguide 3.5.2.1
select {
    &[readonly] {
        background: $extra-light-gray;
    }
    &[disabled] {
        background: $extra-light-gray;
    }
}

// radio
//
// Markup:
// <label>
//   <input name="r" type="radio" value="">ラジオ1
//   <input name="r" type="radio" value="">ラジオ2
// </label>
//
// Styleguide 3.5.3
// readonly / disabled
//
// readonly - 読取専用
// disabled - 無効
//
// Markup:
// <label>
//   <input name="r-{$modifiers}" type="radio" {$modifiers} value="">ラジオ1
//   <input name="r-{$modifiers}" type="radio" {$modifiers} value="">ラジオ2
// </label>
//
// Styleguide 3.5.3.1
// checkbox
//
// Markup:
// <label>
//   <input name="c" type="checkbox" value="">チェック1
//   <input name="c" type="checkbox" value="">チェック2
// </label>
//
// Styleguide 3.5.4
// readonly / disabled
//
// readonly - 読取専用
// disabled - 無効
//
// Markup:
// <label>
//   <input name="c-{$modifiers}" type="checkbox" {$modifiers} value="">チェック1
//   <input name="c-{$modifiers}" type="checkbox" {$modifiers} value="">チェック2
// </label>
//
// Styleguide 3.5.4.1
// submit
//
// Markup:
// <input type="submit" value="submit">
//
// Styleguide 3.5.5
input[type="submit"] {
    @include button($white, $blue);
}

// disabled
//
// disabled - 無効
//
// Markup:
//   <input type="submit" {$modifiers} value="submit">
//
// Styleguide 3.5.5.1
input[type="submit"] {
    &[disabled] {
        @include button($white, $dark-gray);
        opacity: 0.3;
        cursor: default;
        &:hover {
            opacity: 0.3;
        }
    }
}

// styleguide:ignore:start

/*
label{
	vertical-align: middle;
	&:first-child{
		margin: 0 5px 0 0;
	}
}
*/

// styleguide:ignore:end
// .required
//
// 必須項目、ラベルの横に表示します。
//
// Markup:
//   名前<span class="required">*</span>
//
// Styleguide 3.5.6
.required {
    color: red;
    margin-left: 0.25em;
    font-weight: normal;
}

.drill {
    display: block;
    margin-top: 5px;
    &[data-depth="1"] {
        margin-left: 30px;
    }
    &[data-depth="2"] {
        margin-left: 60px;
    }
    &[data-depth="3"] {
        margin-left: 90px;
    }
    &[data-depth="4"] {
        margin-left: 120px;
    }
}

.parts {
    margin-top: 5px;
    margin-left: 30px;
}

label.error {
    color: #da0000;
}

.noty_buttons {
    @include clearfix();
    text-align: center;
    >button.button {
        width: 49%;
        &:first-child {
            float: left;
        }
        &:last-child {
            float: right;
        }
        &:first-child:last-child {
            float: none;
        }
    }
}

input[type="text"].datepicker[readonly]{
    background:#fff;
}


/**/

.boxes{
    @include breakpoint($bp-tablet) {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    &.tenant-info{
        @include breakpoint($bp-tablet) {
            padding-top: 5px;
            padding-bottom: 5px;
        }
        > li{
            table{
                border-collapse: collapse;
                width: calc(100% - 50px);
                th{
                    width: 30%;
                }
                td{
                    padding: 3px 5px;
                    @include breakpoint($bp-tablet) {
                    }
                }
            }
        }
    }
    &.tanto-info{
        @include breakpoint($bp-tablet) {
            padding-top: 5px;
            padding-bottom: 5px;
        }
        > li{
            table{
                border-collapse: collapse;
                width: calc(100% - 50px);
                th{
                    width: 30%;
                }
                td{
                    padding: 3px 5px;
                    @include breakpoint($bp-tablet) {
                    }
                }
            }
        }
    }
    &.carry-info{
        @include breakpoint($bp-tablet) {
            padding-top: 5px;
            padding-bottom: 5px;
        }
        > li{
            table{
                th{
                    width: 15%;
                    font-size: 12px;
                    font-size: 1.2rem;
                    @include breakpoint($bp-tablet) {
                        font-size: 14px;
                        font-size: 1.4rem;
                    }
                }
                td{
                    width: 35%;
                }
            }
        }
    }
    &.special__reg{
        > li{
            table{
                th{
                    width: 15%;
                    font-size: 12px;
                    font-size: 1.2rem;
                    @include breakpoint($bp-tablet) {
                        font-size: 14px;
                        font-size: 1.4rem;
                    }
                }
                .amount{
                    input{
                        width: 40%;
                    }
                }
            }
        }
    }
    > li{
        margin-bottom: 15px;
        border-radius:5px;
        background:$bright-gray;
        box-shadow:0 2px 8px rgba(0,0,0,0.25);
        position: relative;
        overflow:hidden;
        @include breakpoint($bp-tablet) {
            margin-bottom: 10px;
        }
        &:last-child{
            margin-bottom: 0;
        }
        .tenant-delete,
        .tanto-delete{
            width: 36px;
            height: 36px;
            position: absolute;
            top: 50%;
            margin-top: -18px;
            right: 8px;
            text-align: center;
            i.fa{
                font-size: 3.6rem;
                color:#b91414;
            }
        }
        .title{
            background:#03a9f4;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            color:#fff;
            padding: 3px 10px;
            font-weight: bold;
        }
        .indent{
            position: relative;
            padding: 0px 0px;
        }
    }
}

/* 確認画面用 */

form[data-mode="readonly"]{
    input{
        border: none;
        box-shadow:none;
    }
    select{
        border: none;
        box-shadow:none;
    }
}

/**/

.company-type{
    width: 3em;
    display: inline-block;
}
