﻿// Variables
// 
// 変数（定数）を定義します。
//
// Styleguide 1.4.0
//breakpoint
$bp-sp: 480px;
$bp-tablet: 768px;
$bp-pc: 960px;
//PC content width
$site-width: 1120px;
$side-width: 200px;
$content-width: 920px;
// form
$form-border-color: #aaa;
$form-border-radius: 2px;
