﻿.row {
    margin: 0;
    padding: 10px 0;
    box-sizing: border-box;
    position: relative;
    @include clearfix();
    @include breakpoint($bp-tablet) {
        width: auto;
        margin: 0 auto;
    }
}

.col {
    float: left;
}

// .col-one-fifth{
// 	width:20%;
// }
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
    width: 100%;
    float: none;
}

@include breakpoint($bp-tablet) {
    @for $i from 1 through 12 {
        .col-#{$i} {
            width: span($i);
            @if $i !=12 {
                margin-right: gutter();
            }
            float:left;
            &:last-child {
                margin-right: 0;
            }
            &:nth-child(#{12/$i}n) {
                margin-right: 0;
            }
        }
    }
}
