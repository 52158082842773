﻿.account-form {
    @extend %table-input;
    tbody {
        tr {
            th {
                @include breakpoint($bp-tablet) {
                    width: 220px;
                }
            }
        }
    }
}

.account__title {
    @extend %h1-heading;
}

.account__save {
    width: 100%;
    padding: 20px 0;
}

input[name="last_name"],
input[name="first_name"],
input[name="last_kana"],
input[name="first_kana"] {
    @include breakpoint($bp-tablet) {
        width: 50%;
    }
}

input[name="tel_number"]{
    width: 10em;
}

input[name="date_from"],
input[name="date_to"] {
    width: 8em;
}

#first_name-error,
#last_name-error,
#tel_number-error {
    @include breakpoint($bp-tablet) {
        margin-left: 10px;
    }
}

#phone-error,
#date_range-error {
    display: block;
    @include breakpoint($bp-tablet) {
        display: inline-block;
        margin-left: 10px;
    }
}

.label__radio {
    margin-right: 15px;
    input {
        margin-right: 5px;
    }
}
