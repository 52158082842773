// Header
//
// ヘッダを定義します。
// ロゴ、ログアウト、ユーザ名表示はProject/miscに
//
// Markup:
//
// Styleguide 2.1.0
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.12); //スマホの場合はローダーよりヘッダが上でないと操作不能になる
    z-index: 30000;
    @include breakpoint($bp-tablet) {
        z-index: 100;
    }
}

.header__wrapper {
    @include breakpoint($bp-tablet) {
        width: $site-width;
        margin: 0 auto;
    }
}

.header__row {
    position: relative;
    min-height: 44px;
}

.header__bar {
    @include clearfix();
    position: absolute;
    top: 6px;
    right: 52px;
    @include breakpoint($bp-tablet) {
        top: 10px;
        right: 0px;
    }
    .header__info {
        float: right;
        display: inline-block;
        border-radius: 5px;
        margin-left: 10px;
        font-size: 9px;
        font-size: 0.9rem;
        text-decoration: none;
        vertical-align: top;
        text-align: center;
        position: relative;
        >i.fa {
            width: 20px;
            height: 20px;
            border: 1px solid #03a9f4;
            border-radius: 50%;
            font-size: 14px;
            margin: 0 auto 2px;
            display: block;
            &:before {
                position: relative;
                top: 2px;
            }
        }
    }
    .header__mypage,
    .header__logout,
    .header__login {
        float: right;
        display: inline-block;
        border-radius: 5px;
        margin-left: 10px;
        font-size: 9px;
        font-size: 0.9rem;
        text-decoration: none;
        vertical-align: top;
        text-align: center;
        position: relative;
        @include breakpoint($bp-tablet) {
            font-size: 14px;
            font-size: 1.4rem;
        }
    }
    >a {
        color: #03a9f4;
        >i.fa {
            width: 20px;
            font-size: 20px;
            margin: 0 auto 2px;
            display: block;
            @include breakpoint($bp-tablet) {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}

.logo {
    width: 100%;
    margin: 0;
    padding: 10px 0 0 10px;
    a {
        color: #e6feff;
        transition: all ease 0.3s;
        background: url(../img/logo-sp.png) 0 0 / contain no-repeat;
        text-indent: -9999px;
        height: 25px;
        width: 100%;
        display: inline-block;
        @include breakpoint($bp-tablet) {
            background: url(../img/logo.png) 0 0 / contain no-repeat;
        }
        &:hover {
            opacity: 0.85;
        }
    }
}

.telephone {
    @include breakpoint($bp-tablet) {
        padding: 8px 0 0 0;
        text-align: right;
    }
    >img {
        @include breakpoint($bp-tablet) {
            width: 80%;
        }
    }
}
