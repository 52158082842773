// Main
//
// メインコンテンツ部分を定義します。
//
// Styleguide 2.3.0
html {
    min-height: 100%;
    position: relative;
}

body {
    @include breakpoint($bp-tablet) {
        margin-bottom: 120px;
    }
    // &.pos-f{
    //     left:50%;
    //     margin-left:-$site-width / 2;
    // }
}

html,
body {
    // height: 100%;
}

#container {
    width: 100%;
    position: relative;
    @include breakpoint($bp-tablet) {
        @include clearfix();
        width: $site-width;
        margin: 0 auto;
        padding: 30px 0;
    }
}

#content {
    padding-top: 44px;
    padding-bottom: 15px;
    transition: all ease 0.3s;
    position: relative;
    @include breakpoint($bp-tablet) {
        width: 100%;
        float: left;
        padding: 0;
        margin-right: 30px;
        #login &,
        #reminder &,
        #reset & {
            width: 100%;
            float: none;
            padding: 0;
        }
    }
}

body[data-cloak] {
    #content {
        display: none;
    }
}

/* reminder */

.reminder-lead{
    text-align:center;
    margin-bottom: 10px;
}

.reminderForm-input{
    margin-bottom: 15px;
    padding: 10px;
    background: #f3f3f3;
}

.reminderForm-button{
    margin-bottom: 15px;
}

/* mail-send */

.mailsend-lead{
    text-align:center;
    margin-bottom: 10px;
}

.mailsend-input{
    margin-bottom: 15px;
    padding: 10px;
    background: #f3f3f3;
}

.mailsend-button{
    margin-bottom: 15px;
}

/**/

.target_operation{
    display: inline-block;
    width: 100%;
}

.operation-content{
    table-layout: fixed;
    display:block;
    @include breakpoint($bp-tablet) {
        display:table !important;
    }
    tr{
        display:block !important;
        @include breakpoint($bp-tablet) {
            display:table-row !important;
        }
    }
    th{
        display:block !important;
        width:100% !important;
        @include breakpoint($bp-tablet) {
            display:table-cell !important;
            width: 7em !important;
        }
    }
    td{
        display:block !important;
        width:100% !important;
        @include breakpoint($bp-tablet) {
            display:table-cell !important;
        }
        &:nth-child(2){
            @include breakpoint($bp-tablet) {
                width: 30% !important;
            }
        }
    }
}

/**/

.units{
    display:inline-block;
    width:5em;
}
